.Team {
    padding-inline: 100px;
    padding-block: 55px;
}

.external-line {
    border-left: 2px solid #18CE40
}

@media only screen and (max-width: 960px) and (min-width: 480px){
    .Team {
        padding-inline: 40px;
    }
}

@media only screen and (max-width: 480px) {
    .Team {
        padding-inline: 20px;
    }
}

