@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700;800&display=swap');

body {
  margin: 0;
  background-color: #F8F8F8;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*Text*/
.small-bold-text {
  font-size: 15px;
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  word-wrap: break-word;
}

.small-text {
  font-size: 15px;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  word-wrap: break-word;
}

.medium-text {
  font-size: 20px;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  word-wrap: break-word;
}

.medium-bold-text {
  font-size: 20px;
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  word-wrap: break-word;
}

.green-text {
  color: #18ce40;
}

.grey-text {
  color: #464646;
}

.white-text {
  color: #fff;
}

/*Title*/
.extra-large-title {
  color: #464646;
  font-size: 60px;
  font-family: 'Manrope', sans-serif;
  font-weight: 900;
  word-wrap: break-word;
}

.large-title {
  font-size: 40px;
  font-family: 'Manrope', sans-serif;
  font-weight: 900;
  word-wrap: break-word;
}

.small-title {
  color: #464646;
  font-size: 20px;
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  word-wrap: break-word;
}

/*Button*/
.green-button {
  height: 50px;
  border-radius: 25px;
  padding-inline: 25px;
  border: 0;
  color: #fff;
  font-size: 15px;
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  background-color: #18ce40;
}

.green-button:disabled {
  background-color: #828282;
}

.grey-button:disabled {
  background-color: #828282;
}

.grey-button {
  height: 50px;
  border-radius: 25px;
  padding-inline: 25px;
  border: 0;
  color: #fff;
  font-size: 15px;
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  background-color: #464646;
}

.transparent-button {
  background-color: transparent;
  border: 0;
}

/*Background*/
.bg-green {
  background-color: #18ce40;
}

.bg-light-green {
  background-color: #18ce4020;
}

img {
  -webkit-user-drag: none;
  user-select: none;
}

@media only screen and (max-width: 768px) {
  .medium-text, .medium-bold-text, .small-title {
    font-size: 15px;
  }

  .extra-large-title {
    font-size: 50px;
  }

  .large-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 960px) and (min-width: 768px) {
  .medium-text, .medium-bold-text, .small-title {
    font-size: 18px;
  }
}