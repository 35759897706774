.Header {
  padding-inline: 100px;
  height: 100px;
  background-color: white;
}

.Header-logo {
  height: 40px;
  pointer-events: none;
}

.Header-underline-animation {
  position: relative;
  text-decoration: none;
}
.Header-underline-animation:hover {
  color: #18ce40;
}
.Header-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #18ce40;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
  transform: scaleX(0);
}

.Header-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


@media only screen and (max-width: 768px) {
  .Header {
    padding-inline: 20px;
    height: 50px;
  }
}

@media only screen and (max-width: 1230px) and (min-width: 768px) {
  .Header {
    padding-inline: 40px;
    height: 80px;
  }
}
